import i18n from 'i18next';

const t = (value) => {
  return i18n.t(value)
}

export const months = () => {
  return [
    t('calendar.months.january'),
    t('calendar.months.february'),
    t('calendar.months.march'),
    t('calendar.months.april'),
    t('calendar.months.may'),
    t('calendar.months.june'),
    t('calendar.months.july'),
    t('calendar.months.august'),
    t('calendar.months.september'),
    t('calendar.months.october'),
    t('calendar.months.november'),
    t('calendar.months.december')
  ]
}

export const daysOfWeek = () => {
  return [
  t('calendar.days.sun'),
  t('calendar.days.mon'),
  t('calendar.days.tue'),
  t('calendar.days.wed'),
  t('calendar.days.thu'),
  t('calendar.days.fri'),
  t('calendar.days.sat')
]
}

export const formatDate = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date)
  }

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)
  return `${date.getFullYear()}-${month}-${day}`
}

export const formatTime = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date)
  }
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  return `${hours}:${minutes}:${seconds}`
}

export const formatCurrentTime = () => {
  const date = new Date()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours < 10 ? `0${hours}` : 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  return `${hours}:${minutes} ${ampm}`
}

export const formatDateMonthYear = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date)
  }

  return `${months[date.getMonth()]} ${date.getFullYear()}`
}
