import React from 'react'
import './footer.scss'
import {ColteneLogo} from "../../../images";
import {ColteneLogoWhite} from "../../../images";
import {useTranslation} from "react-i18next";
import {FacebookIcon, LinkedInIcon, TwitterIcon, YoutubeIcon} from "../../../icons";
import {useWindowSize} from "../../../libs/hooks";

function Footer(props) {
    const {t} = useTranslation()

    const curYear = (new Date()).getFullYear()

    const isMobile = useWindowSize()[0] <= 568

    return (
        <div className="footer-wrapper">
            <div className="d-flex flex-justify-between bg-lightgray padding-wrapper pt-25 pb-25">
                <div className="d-flex flex-column">
                    <a className="color-primary link"
                       href="https://global.coltene.com/information/imprint/">{t('footer.imprint')}</a>
                    <a className="color-primary link"
                       href="https://global.coltene.com/company/sales-branches/">{t('footer.contact')}</a>
                </div>
                <div>
                    {
                        isMobile ?
                            <img src={ColteneLogoWhite} alt="logo" className="logo"/>
                            :
                            <img src={ColteneLogo} alt="logo" className="logo"/>
                    }
                </div>
            </div>
            <div className="d-flex flex-justify-between bg-black padding-wrapper">
                <div className="d-flex copyright">
                  {t('footer.copyright', {year: curYear})}
                </div>
                <div className={`d-flex flex-justify-between ${isMobile && 'w-100'}`}>
                    <a className="fill-white link" href="https://www.youtube.com/user/coltenewhaledent">
                        <YoutubeIcon/>
                    </a>
                    <a className="fill-white link" href="https://www.facebook.com/COLTENE.UK?ref=br_rs">
                        <FacebookIcon/>
                    </a>
                    <a className="fill-white link" href="https://www.linkedin.com/company/coltene-europe/">
                        <LinkedInIcon/>
                    </a>
                    <a className="fill-white link" href="https://twitter.com/coltenedental">
                        <TwitterIcon/>
                    </a>
                </div>
            </div>
        </div>
    )
}


export default Footer
