import React from 'react'
import PropTypes from "prop-types";
import Header from "../common/Header";
import Footer from "../common/Footer";

const MainLayout = (props) => {

  return (
    <div className="main-layout">
      <Header/>
      {props.children && props.children}
      <Footer/>
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.object.isRequired
}

export default MainLayout
