import React  from "react";
import {CardElement} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

const CardForm = (props) => {
  const {options, onChange} = props

  return (
    <CardElement
      options={options}
      onChange={(e) => onChange(e)}
    />
  );
};

CardForm.propTypes = {
  options: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default CardForm;
