import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import './home.scss'
import {useTranslation} from 'react-i18next'
import {Close, CloseCircleIcon, DefaultIcon, HeaterIcon} from "../../icons";
import CalendarInput from "../common/CalendarInput";
import {_baseGetFetch, _basePostFetch} from "../../utils/baseFetch";
import MainLayout from "../MainLayout";
import {formatDate, formatTime} from "../../utils/filters/date";
import {toast} from "react-toastify";
import {useWindowSize} from "../../libs/hooks";
import {Elements} from '@stripe/react-stripe-js';
import StripeForm from '../common/StripeForm';
import {useAppContext} from "../../libs/contextLib";


const Home = (props) => {
  let {id} = useParams();
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState(0)
  const [activeOption, setActiveOption] = useState(null)
  const [congratulationsModal, setCongratulationsModal] = useState(false)
  const [terms, setTerms] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(false)
  const [device, setDevice] = useState({})
  const [functions, setFunctions] = useState({})
  const [prices, setPrices] = useState({})
  const [activeCurrency, setActiveCurrency] = useState({})
  const [transaction, setTransaction] = useState({})
  const [errorsMsg, setErrors] = useState([])
  const initialFormState = {
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    serial_number: '',
    code: ''
  }
  const [formData, updateFormData] = React.useState(initialFormState)
  const [stripeElement, setStripeElement] = useState(null)
  const [isFree, setFreeProduct] = useState(false)
  const [discount, setDiscount] = useState(null)
  const [code, setCode] = useState(null)
  const {stripePromise} = useAppContext()
  const isMobile = useWindowSize()[0] <= 768

  useEffect(() => {
    _baseGetFetch(`/devices/${id}`)
      .then(data => {
        setDevice(data.data)
      })

    _baseGetFetch(`/devices/${id}/functions`)
      .then(data => {
        setFunctions(data.data)
        if (data.data && data.data.length === 1) {
          setActiveOption(data.data[0])
        }
      })

    _baseGetFetch(`/functions/${id}/prices`)
      .then(data => {
        setPrices(data.data)
        if (data && data.data && data.data.length) {
          setActiveCurrency(data.data[0])
        }
      })

  }, [props])

  const checkPromo = () => {
    _baseGetFetch(`/check-code/${formData.code}/${activeOption.id}`)
      .then(data => {
        setDiscount(data.data)
        setCode(formData.code)
        updateFormData({...formData,
          code: ''
        })
        if (data.data.discount === 100) {
          setFreeProduct(true)
        }
      })
      .catch(error => {
        toast.error(t('errors.invalid-promo'))
        updateFormData({
          ...formData,
          code: null
        })
      })

  }

  const deletePromo = () => {
    if (discount) {
      setCode(null)
      setDiscount(null)
      setFreeProduct(false)
    }
  }

  const calculate = (activeCurrency, code = null) => {
    const price = activeCurrency

    let amount = Math.round((price.amount || 0) * 100) / 100
    let amount_total = amount

    let amount_discount = 0
    const discount = code && code.discount ? Math.round(code.discount * 100) / 100 : 0
    if (discount) {
      amount_discount = Math.round(amount_total * discount) / 100
    }
    amount_total -= amount_discount

    let amount_hst = 0
    const hst = Math.round(price.hst * 100) / 100
    if (hst) {
      amount_hst = Math.round(amount_total * hst) / 100
    }
    amount_total += amount_hst

    let amount_shipping = 0
    if (price.shipping) {
      amount_shipping = Math.round(price.shipping * 100) / 100
    }
    amount_total += amount_shipping

    return {
      function_id: price.function_id,
      currency_code: price.currency_code,
      currency_symbol: price.currency_symbol,
      amount,
      discount,
      amount_discount,
      hst,
      amount_hst,
      amount_shipping,
      amount_total,
      description: code ? code.description : '',
    }
  }

  const total = calculate(activeCurrency, discount)

  const tabs = [
    {
      title: t('tabs.title.step-1'),
      description: t('tabs.description.step-1'),
    },
    {
      title: t('tabs.title.step-2'),
      description: t('tabs.description.step-2'),
    },
    {
      title: t('tabs.title.step-3'),
      description: t('tabs.description.step-3'),
    },
    {
      title: t('tabs.title.step-4'),
      description: t('tabs.description.step-4'),
    }
  ]

  const changeTab = (index) => {
    if (activeTab === 0) {
      if (canBeClicked(index) && activeOption) {
        setActiveTab(index)
      } else {
        toast.error(t('errors.function-required'))
        toast.clearWaitingQueue();
      }
    }

    if (activeTab === 1) {
      if (formData.serial_number && validateSerialNumber(formData.serial_number) && formData.date_of_purchase) {
        if (canBeClicked(index)) {
          setActiveTab(index)
        }
      } else {
        if (index > 1) {
          toast.error(t('errors.serial-number-required'))
          toast.clearWaitingQueue();
        }
      }
    }

    if (activeTab === 2) {
      if (canBeClicked(index)) {
        setActiveTab(index)
      }
    }

    if (index === activeTab - 1) {
      if (canBeClicked(index)) {
        setActiveTab(index)
      }
    }
  }

  const toggleModal = (open) => {
    if (!open) {
      setActiveTab(0)
      setFreeProduct(false)
      handleReset()
    }
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = open ? 'hidden' : 'auto'

    setCongratulationsModal(open)
  }

  const canBeClicked = (index) => {
    if (index === activeTab || index === (activeTab + 1) || index === (activeTab - 1)) {
      return 'can-click'
    }
  }

  const changeCurrency = (e) => {
    const value = e.target.value
    const currency = prices.find(it => it.currency_code === value)
    setActiveCurrency(currency)
  }

  const handleReset = () => {
    updateFormData({initialFormState})
    setDiscount(null)
    setCode(null)
    setErrors([])
    if (stripeElement) {
      stripeElement.clear()
    }
  }

  const placeOrder = () => {
    if (!terms) {
      toast.error(t('errors.terms'))
      toast.clearWaitingQueue();
      return
    }

    const data = {
      ...formData,
      price_id: activeCurrency.id,
      code: code
    }

    if (!discount) {
      data.code = null
    }

    _basePostFetch(`/transactions`, data)
      .then(data => {
        setTransaction(data.data)
        setTerms(false)
        toggleModal(true)
      })
      .catch(error => {
        if (error.response && error.response.status && error.response.status !== 200) {
          const errors = error.response.data.errors
          if (errors.find((it) => it.param === 'card_token')) {
            toast.error(t('errors.stripe-error'))
            toast.clearWaitingQueue();
          }
          setErrors(errors)
        } else {
          toast.error(t('errors.all-fields'))
          toast.clearWaitingQueue();
        }
      })
  }

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      [e.target.name]: e.target.value
    });
  }

  const validateSerialNumber = () => {

    const value = formData.serial_number
    let error = ''
    const year = value.substr(0, 2)
    const month = value.substr(2, 2)
    const day = value.substr(4, 2)
    const index = value.substr(6)
    const date = new Date(`20${year}-${month}-${day}`)

    if (value.length === 9) {
      if (date.getUTCFullYear() === parseInt(`20${year}`)) {
        if ((date.getUTCMonth() + 1) === parseInt(month)) {
          if (date.getUTCDate() === parseInt(day)) {
            if (index.match(new RegExp('[0-9]{3}'))) {
              setErrors([])
              return true
            } else {
              error = t('errors.invalid-serial-number')
            }
          } else {
            error = t('errors.invalid-serial-number')
          }
        } else {
          error = t('errors.invalid-serial-number')
        }
      } else {
        error = t('errors.invalid-serial-number')
      }
    } else {
      error = t('errors.invalid-serial-number')
    }
    setErrors([{msg: error, param: "serial_number"}])
    return false
  }
  const displayError = (param) => {
    if (errorsMsg && errorsMsg.length) {
      const error = errorsMsg.find(it => it.param === param)
      if (error) {
        return error.msg
      }
    }
  }

  const handleChangeCalendarDate = (e) => {
    const value = e
    if (formData.date_of_purchase) {
      updateFormData({
        ...formData,
        date_of_purchase: value
      })
    }
    if (value) {
      updateFormData({
        ...formData,
        date_of_purchase: value
      })
    }
  }

  const customIcon = (icon) => (
    <div className="primary-blue-filter">
      <img xlinkHref={icon} src={icon}/>
    </div>
  )

  const renderIcon = (icon) => {
    if (icon.includes('://') || icon.includes('data:')) {
      return customIcon(icon)
    } else {
      return <DefaultIcon class="tab-icon-wrapper"/>
    }
  }

  const imageSwitcher = (imageSrc) => {
    if (imageSrc.includes('://') || imageSrc.includes('data:')) {
      return imageSrc
    } else {
      var index = imageSrc.lastIndexOf("/") + 1;
      var imageName = imageSrc.substr(index);
      return renderImage(imageName)
    }
  }

  const renderImage = (imageName) => {
    try {
      return require(`../../images/${imageName}`).default
    } catch (ex) {
      return ''
    }
  }

  const handlePaymentToken = (token) => {
    if (token) {
      updateFormData({
        ...formData,
        card_token: token
      })

    }
  }

  return (
    <MainLayout>
      <div className="home-page">
        <div className="product-wrapper ">
          <div className="product-description d-flex flex-align-center flex-justify-center">
            <div className={`${isMobile ? 'product-description-mobile' : ''}`}>
              <h1 className="title">{device.name && device.name}</h1>
              <h3 className="subtitle">{device.type && device.type}</h3>
              <p className="description">{device.description && device.description}</p>
            </div>
          </div>
          <div className="d-flex flex-align-center flex-justify-center">
            {
              device.image && (
                <img className="product-image" src={imageSwitcher(device.image)} alt=""/>
              )
            }
          </div>
        </div>
        <div className="separator"/>
        <div className="title-wrapper d-flex flex-align-center">
          <h4 className="title">{t('product.details')}</h4>
        </div>
        <div className="tabs-wrapper d-flex flex-column flex-align-center padding-wrapper">
          {
            tabs && tabs.length && (
              <div className="tabs-description">
                <h5 className="m-0">{activeTab + 1}. {tabs[activeTab].description}</h5>
              </div>
            )
          }
          <div className="tab-headers d-flex">
            {
              tabs && tabs.length && (
                tabs.map((item, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={`tab-header d-flex flex-column flex-align-center ${activeTab === index ? 'active' : ''} ${activeTab > index ? 'inactive' : ''}`}>
                      {
                        index !== 0 && (
                          <div className="bubble-separator left"/>
                        )
                      }
                      <div
                        className={`tab-bubble d-flex flex-align-center flex-justify-center ${canBeClicked(index)}`}
                        onClick={() => changeTab(index)}>
                        {index + 1}
                      </div>
                      <h5 className="tab-title">{item.title}</h5>
                      {
                        (index + 1) !== tabs.length && (
                          <div className="bubble-separator right"/>
                        )
                      }
                    </div>
                  </React.Fragment>
                ))
              )
            }
          </div>
          <div className={`tabs-content ${activeTab !== 0 ? 'custom-width' : ''}`}>
            {
              activeTab === 0 && (
                <div className={`d-flex flex-align-center flex-column text-center tab-${activeTab}`}>
                  <p className="subtitle">{t('tabs.chosen-device')}</p>
                  <h2 className="title">{device.name && device.name} {device.type && device.type}</h2>
                  <div className="grey-content d-grid tw-4 mb-35 mt-35">
                    {
                      device && functions && functions.length &&
                      functions.map((option, index) => (
                          <div
                            key={index}
                            className={`option cursor-pointer ${activeOption && activeOption.id === option.id ? 'active' : ''}`}
                            onClick={() => setActiveOption(option)}>
                            <div className="icon-wrapper">
                              {renderIcon(option.icon)}
                            </div>
                            <h4 className="option-title">{option.name}</h4>
                            <p className="option-description">{option.description}</p>
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
              )
            }
            {
              activeTab === 1 && (
                <div className={`d-flex flex-align-center flex-column text-center tab-${activeTab}`}>
                  <p className="subtitle">{t('tabs.chosen-device')}</p>
                  <h2 className="title">{device.name}</h2>
                  <div className="tab-icon-wrapper mt-60 mb-35">
                    {activeOption && renderIcon(activeOption.icon)}
                    {activeOption && activeOption.name}
                  </div>
                  <div className="tab-price-wrapper">
                    <div className="price">
                      <h3>{t('price.locale', {currency: activeCurrency && activeCurrency.currency_symbol})}: {activeCurrency && activeCurrency.amount}</h3>
                    </div>
                    <div className="currency">
                      <select name="currency" onChange={changeCurrency}>
                        {
                          prices && prices.length && prices.map((currency, index) => (

                            <option key={index} value={currency.currency_code}>
                              {currency.currency_code}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  <div className="promo-code-wrapper">
                    {t('promo.code-info')}
                  </div>
                  <div className="device-wrapper">
                    <h3 className="title">{t('promo.device-title')}</h3>
                    <div className="input-wrapper">
                      <label htmlFor="serial_number">{t('tab.serial-number')}
                        <span>*</span></label>
                      <input type="text" placeholder={t('input.serial-number')} id="serial_number"
                             name="serial_number" onChange={handleChange} required
                             onBlur={validateSerialNumber}
                             minLength="9" maxLength="9" value={formData.serial_number || ''}/>
                      <p className="error">{displayError('serial_number')}</p>
                    </div>
                    <div className="input-wrapper">
                      <label>{t('input.date-of-purchase')} <span>*</span></label>
                      <div className="calendar-input-wrapper">
                        <CalendarInput onChangeDay={(e) => handleChangeCalendarDate(e)}
                                       value={formData.date_of_purchase || ''}/>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              activeTab === 2 && (
                <div className={`d-flex flex-align-center flex-column tab-${activeTab}`}>
                  <div className="w-100">
                    <div className="grey-content mb-35">
                      <h4 className="your-device">{t('tab.your-device')}</h4>
                      <h3 className="title">{device.name}</h3>
                      <p
                        className="serial-number">{t('tab.serial-number')}: {formData.serial_number}</p>
                      <h4 className="selected-function">{t('tab.selected-function')}</h4>
                      <div className="d-flex flex-align-center flex-justify-between">
                        <div className="tab-icon-wrapper">
                          {renderIcon(activeOption.icon)}
                          {activeOption.name}
                        </div>
                        <div className='price-wrapper'>
                          <p>{activeCurrency.currency_code} {activeCurrency.currency_symbol}{activeCurrency.amount}</p>
                        </div>
                      </div>
                      <div className="promo-code">
                        <input type="text" placeholder={t('promo.promo-code-info')} name="code"
                               onChange={handleChange} value={formData.code || ''}/>
                          <button onClick={() => checkPromo()}>
                            {t('buttons.add-code')}
                          </button>
                      </div>
                      {discount && code &&
                      <div className="promo-code-delete d-flex flex-align-center flex-justify-between">
                        <div className="promo-code-delete-wrapper d-flex flex-align-center">
                          <div className="code d-flex d-flex flex-align-center">
                            <div>{t('promo.promo-code-info')} :</div>
                            <p>{code}</p>
                          </div>
                          <div className="discount d-flex flex-align-center">
                            <div>{t('tab.discount')} :</div>
                            <p>{discount.discount} %</p>
                          </div>
                        </div>
                        <div className="reduced-price cursor-pointer d-flex" onClick={deletePromo}>
                          <div>
                            {total.amount_discount && `-${total.currency_symbol}${total.amount_discount}`}
                          </div>
                          <Close/>
                        </div>
                      </div>
                      }
                      <div className="promo-calculations">
                        <div className="subtotal d-flex flex-align-center flex-justify-between">
                          <div>{t('tab.subtotal')}</div>
                          <div>{total.currency_symbol}{activeCurrency.amount}</div>
                        </div>
                        <div className="discount d-flex flex-align-center flex-justify-between">
                          <div>{t('tab.promo-code-discount')}</div>
                          <div>{total.amount_discount && `-${total.currency_symbol}${total.amount_discount}`}</div>
                        </div>
                        <div className="shipping d-flex flex-align-center flex-justify-between">
                          <div>{t('tab.shipping')}</div>
                          <div>{total.amount_shipping === 0 ? t('tab.free-shipping') : total.amount_shipping}</div>
                        </div>
                        {total.hst !== 0 &&
                          <div className="hst d-flex flex-align-center flex-justify-between">
                            <div>{t('tab.hst')}</div>
                            <div>{total.currency_symbol}{total.amount_hst}</div>
                          </div>
                        }
                      </div>
                      <div className="total d-flex flex-align-center flex-justify-between">
                        <div>{t('tab.total')}</div>
                        <div>{total.currency_code}{total.currency_symbol}{total.amount_total}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            {
              activeTab === 3 && (
                <div className={`d-flex flex-align-center flex-column tab-${activeTab}`}>
                  <div className="w-100">
                    <div className="grey-content">
                      <h2 className="preview">{t('tab.preview')}</h2>
                      <div>
                        <h4 className="your-device">{t('tab.your-device')}</h4>
                        <h3 className="title">{device.name}</h3>
                        <p
                          className="serial-number">{t('tab.serial-number')}: {formData.serial_number}</p>
                      </div>
                      <div>
                        <h4 className="selected-function">{t('tab.selected-function')}</h4>
                        <div className="tab-icon-wrapper">
                          {renderIcon(activeOption.icon)}
                          {activeOption.name}
                        </div>
                      </div>
                      <div className="d-flex">
                        {discount && code &&
                        <div>
                          <h4 className="selected-function">{t('tab.discount')}</h4>
                          <div className="total-discount">
                            {total.currency_symbol}{total.amount_discount}
                          </div>
                        </div>
                        }
                        <div className={discount ? 'space' : ''}>
                          <h4 className="selected-function">{t('tab.order-total', {currency: total.currency_code})}</h4>
                          <div className="total-amount">
                            {total.currency_symbol}{total.amount_total}
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3 className="contact-title">{t('tab.contact-title')}</h3>
                    <form action="#" autoComplete="off">
                      <div className="input-wrapper">
                        <label htmlFor="name">{t('input.name')} <span>*</span></label>
                        <input type="text" id="name" name="name" onChange={handleChange}
                               value={formData.name || ''}/>
                        <p className="error">{displayError('name')}</p>
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="email">{t('input.email')} <span>*</span></label>
                        <input type="text" id="email" name="email" onChange={handleChange}
                               value={formData.email || ''}/>
                        <p className="error">{displayError('email')}</p>
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="address">{t('input.address')} <span>*</span></label>
                        <input type="text" id="address" name="address" onChange={handleChange}
                               value={formData.address || ''}/>
                        <p className="error">{displayError('address')}</p>
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="city">{t('input.city')} <span>*</span></label>
                        <input type="text" id="city" name="city" onChange={handleChange}
                               value={formData.city || ''}/>
                        <p className="error">{displayError('city')}</p>
                      </div>
                      <div className="d-flex grouped">
                        <div className="input-wrapper">
                          <label htmlFor="state">{t('input.state')} <span>*</span></label>
                          <input type="text" id="state" name="state" onChange={handleChange}
                                 value={formData.state || ''}/>
                          <p className="error">{displayError('state')}</p>
                        </div>
                        <div className="input-wrapper">
                          <label htmlFor="zip">{t('input.zip')} <span>*</span></label>
                          <input type="text" id="zip" name="zip" onChange={handleChange}
                                 value={formData.zip || ''}/>
                          <p className="error">{displayError('zip')}</p>
                        </div>
                      </div>
                      <div className="input-wrapper">
                        <label htmlFor="country">{t('input.country')} <span>*</span></label>
                        <input type="text" id="country" name="country" onChange={handleChange}
                               value={formData.country || ''}/>
                        <p className="error">{displayError('country')}</p>
                      </div>
                      {!isFree && (
                        <React.Fragment>
                          <h3 className="payment-title">
                            {t('tab.payment-title')}
                          </h3>
                          <label className="card-label">
                            {t('input.card-label')}
                            <span> * </span>
                          </label>
                          {
                            stripePromise &&
                            <Elements stripe={stripePromise}>
                              <StripeForm reset={(el) => setStripeElement(el)}
                                          onChange={handlePaymentToken}/>
                              <div className="input-wrapper">
                                <p className="error-stripe">{displayError('card_token')}</p>
                              </div>
                            </Elements>
                          }
                        </React.Fragment>
                      )}
                      <div className="terms-wrapper">
                        <input
                          type="checkbox"
                          value={formData.terms}
                          id="terms"
                          name="terms"
                          checked={terms}
                          onChange={() => setTerms(!terms)}/>
                        <label htmlFor="terms">
                          {t('tab.terms1')}
                          <a href="https://nam.coltene.com/information/data-protection/">
                            {t('tab.policy')}
                          </a>
                          {t('tab.terms2')}
                          <span> * </span>
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              )
            }
            <div
              className={`buttons-wrapper ${activeTab === 0 ? 'flex-end' : ''}${activeTab === 2 ? 'background' : ''}`}>
              {
                activeTab !== 0 && (
                  <button className="back" onClick={() => changeTab(activeTab - 1)}>
                    {t('buttons.back')}
                  </button>
                )
              }
              {
                (activeTab + 1) < tabs.length && (
                  <button className="next" onClick={() => changeTab(activeTab + 1)}>
                    {t('buttons.next')}
                  </button>
                )
              }
              {
                (activeTab + 1) === tabs.length && (
                  <button className="next" onClick={placeOrder}>
                    {t('buttons.place-order')}
                  </button>
                )
              }

            </div>
          </div>
        </div>
        {
          congratulationsModal && (
            <div className="congratulations-modal">
              <div className="overlay" onClick={() => toggleModal(false)}/>
              <div className="modal">
                <CloseCircleIcon className="close-button" onClick={() => toggleModal(false)}/>
                <h2 className="title">{t('modal.title')}</h2>
                <div className="activation-code">
                  <div className="code-wrapper">
                    {t('modal.activation-code', {heater: activeOption.name})}: <span>{transaction}</span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </MainLayout>
  )
}

export default Home
