import {useTranslation} from "react-i18next";
import "./calendar-input.scss"
import React, {useEffect, useRef, useState} from "react";
import {ArrowBackIcon, CalendarIcon} from "../../../icons";
import PropTypes from "prop-types";
import {months, daysOfWeek} from "../../../utils/filters/date";


const CalendarInput = (props) => {
    const {t} = useTranslation()

    const {onChangeDay, value} = props
    const [showCalendar, setShowCalendar] = useState(false)
    const [selectedDate, setSelectedDate] = useState(value ? value : null)
    const [date, setDate] = useState({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
    })

    const localMonths = months()
    const localDaysOfWeek = daysOfWeek()

    const ref = useRef('calendar');

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowCalendar(false)
        }
    }

    useEffect(() => {
        if (selectedDate) {
          const initialDate = new Date(Date.parse(selectedDate))
          setDate({month: initialDate.getMonth(), year: initialDate.getFullYear()})
        }

        if (showCalendar) {
            const options = document.getElementById('calendar-options')
            const input = document.getElementById('calendar-input')

            if (input && options) {
                const i = input.getBoundingClientRect()
                const o = options.getBoundingClientRect()

                if (document.documentElement.clientHeight < (i.height + i.top + o.height)) {
                    options.style.top = `-${o.height}px`
                }
            }
        }

        document.addEventListener("click", handleClickOutside, true)
        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [props, showCalendar, selectedDate])

  const formatVal = (value) => {
    return value < 10 ? `0${value}` : value
  }

  const datesForGrid = (year, month) => {
    const dates = []
    const firstDay = new Date(year, month).getDay()
    const lastDay = new Date(year, month + 1, 0).getDay()
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate()
    const totalDaysInPrevMonth = new Date(year, month, 0).getDate()

    let currentDate = selectedDate || `${(new Date()).getFullYear()}-${formatVal((new Date()).getMonth()+1)}-${formatVal((new Date()).getDate())}`

    for (let i = 1; i <= firstDay; i++) {
      const prevMonthDate = totalDaysInPrevMonth - firstDay + i
      const key = `${date.year}-${formatVal(date.month)}-${formatVal(prevMonthDate)}`
      dates.push({key: key, date: prevMonthDate, monthClass: 'prev'})
    }

    for (let i = 1; i <= totalDaysInMonth; i++) {
      const key = `${date.year}-${formatVal(date.month + 1)}-${formatVal(i)}`
      let object = {key: key, date: i, monthClass: 'current'}
      if (key === currentDate) {
        object.selectedClass = 'selected'
      }

      if (i < 10) {
        object.date = `0${object.date}`
      }

      dates.push(object)
    }


        const diff = daysOfWeek().length - (lastDay + 1)

        if (dates.length < (dates.length + diff)) {
            const count = (dates.length + diff) - dates.length
            for (let i = 1; i <= count; i++) {
              const key = `${date.year}-${formatVal(date.month + 2)}-${formatVal(i)}`

                if (i < 10) {
                    i = `0${i}`
                }

                dates.push({key: key, date: i, monthClass: 'next'})
            }
        }
        return dates
    }

    const prevMonth = (e) => {
        e.preventDefault()
        const localDate = new Date(date.year, date.month - 1)
        setDate({
            month: localDate.getMonth(),
            year: localDate.getFullYear(),
        })
    }

    const nextMonth = (e) => {
        e.preventDefault()
        const localDate = new Date(date.year, date.month + 1)
        setDate({
            month: localDate.getMonth(),
            year: localDate.getFullYear(),
        })
    }

    const selectDay = (e) => {
      const value = e.target.id
        setSelectedDate(value)
        onChangeDay(value)
        setShowCalendar(false)
    }

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar)
    }

    return (
        <div id="calendar-input" ref={ref} className="calendar-wrapper">
            <div className="calendar-input-wrapper">
                <div className="calendar-input" onClick={() => toggleCalendar()}>
                    <div
                        className="custom-selected">{selectedDate ? selectedDate : t('placeholders.date-of-purchase')}</div>
                    <CalendarIcon/>
                </div>
            </div>
            {
                showCalendar && (
                    <div id="calendar-options" className="calendar">
                        <div className="calendar-header">
                            <button onClick={(e) => prevMonth(e)}>
                                <ArrowBackIcon className="left"/>
                            </button>
                            <h2 className="month">{localMonths[date.month]} {date.year}</h2>
                            <button onClick={(e) => nextMonth(e)}>
                                <ArrowBackIcon className="right"/>
                            </button>
                        </div>
                        <div className='calendar-content'>
                            {
                                localDaysOfWeek.map((day, index) => (
                                    <div className="text-gray day-of-week" key={`day-${index}`}>{day}</div>
                                ))
                            }
                            {
                                datesForGrid(date.year, date.month).map((date, index) => (
                                    <div className="date" key={date.key}>
                                        <div
                                            id={date.key}
                                            onClick={selectDay}
                                            className={`${date.monthClass}
                                            ${
                                                date.selectedClass
                                                    ? `${date.selectedClass} cursor-pointer`
                                                    : 'cursor-pointer'
                                                }
                                            `}>
                                            {date.date}
                                        </div>
                                        <div className="dots d-flex">
                                            <div className={`dot ${date.dot ? date.dot : ''}`}/>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}
CalendarInput.propTypes = {
    onChangeDay: PropTypes.func.isRequired,
    onChangeMonth: PropTypes.func,
    printouts: PropTypes.array,
    value: PropTypes.string
}

export default CalendarInput
