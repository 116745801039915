import Image from './image.jpg'
import ColteneLogo from './coltene-logo.png'
import ColteneLogoWhite from './coltene-logo-white.png'
import BioSonic from './bio-sonic.png'
export {
  Image,
  ColteneLogo,
  ColteneLogoWhite,
  BioSonic
}
