import React from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from "@stripe/react-stripe-js";

import PropTypes from "prop-types";

const SplitForm = (props) => {
  const {options, onChange} = props

  return (
    <div>
      <CardNumberElement
        options={options}
        onChange={(e) => onChange(e)}
      />
      <div className="grouped">
        <CardExpiryElement
          options={options}
          onChange={(e) => onChange(e)}
        />
        <CardCvcElement
          options={options}
          onChange={(e) => onChange(e)}
        />
      </div>
    </div>
  );
};

SplitForm.propTypes = {
  options: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default SplitForm;
