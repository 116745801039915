import { Auth } from "aws-amplify"
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const GET_AUTH_USER = 'GET_AUTH_USER'

function setAuthedUser (authedUser) {
    return {
        type: SET_AUTH_USER,
        authedUser
    }
}

export function handleSetAuthedUser (params) {
    return (dispatch) => {
        return dispatch(setAuthedUser(params))
    }
}

export function handleUserLogout () {
    return (dispatch) => {
        return Auth.signOut()
            .then ( () => {
            dispatch(setAuthedUser(null))
        })
    }
}