import React, {useEffect, useState} from 'react'
import {withRouter} from "react-router-dom"
import {connect} from 'react-redux'
import {Auth} from "aws-amplify"
import {AppContext} from "../libs/contextLib"
import {useTranslation} from 'react-i18next'
import './App.scss'
import Routes from "./common/Routes";
import {loadStripe} from '@stripe/stripe-js';

function App(props) {
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [isAuthenticating, setAuthenticating] = useState(true)
  const {t} = useTranslation()

  const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)

  useEffect(() => {
    const controller = new AbortController()
    // onLoad()
    const onLoad = () => {
      const {dispatch} = props

      setAuthenticated(true)
      setAuthenticating(false)
    }

    const onSessionInactive = () => {
      const {dispatch} = props
      console.log('--- setting time out')
      Auth.signOut()
        .then(() => setAuthenticated(false))
    }

    onLoad()

    const tout = 8 * 1000 * 60 * 60
    let exp

    if (isAuthenticated) {
      exp = setTimeout(() => onSessionInactive(), tout)
    }

    return () => {
      controller.abort()

      if (exp) {
        clearTimeout(exp)
      }
    }
  }, [t, props])

  return (
    !isAuthenticating ?
      (
        <div id="main-content">
          <AppContext.Provider value={{isAuthenticated, setAuthenticated, stripePromise}}>
            <div id="page-content">
              <Routes />
            </div>
          </AppContext.Provider>
        </div>
      )
      : isAuthenticating &&
      (
        <div>
          Loading .....
        </div>
      )
  )
}

export default withRouter(connect()(App))
