import React from 'react'
import  './loading-screen.scss'
import { useTranslation } from 'react-i18next'
import {ColteneLogo} from "../../images";

const LoadingScreen = (props) => {
  const { t } = useTranslation()

  return (
    <div className="page-loading-screen">
      <div>
        <img src={ColteneLogo} alt="logo" className="logo"/>
      </div>
    </div>
  )
}

export default LoadingScreen
