import React, {useEffect, useState} from 'react'
import {Redirect, Route} from 'react-router-dom'
import {_baseGetFetch} from "../../../../utils/baseFetch";
import LoadingScreen from "../../../LoadingScreen";

const PublicRoute = ({component: Component, to, ...rest}) => {
  const [deviceId, setDeviceId] = useState(null)
  const [toPath, setToPath] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    if (!deviceId) {
      setLoading(true)
      _baseGetFetch(`/devices`)
        .then(data => {
          if (data && data.data && data.data[0]) {
            const id = data.data[0].id
            setToPath(to.replace(":id", id))
            setDeviceId(id)
          }
        })
    }
  })

  return (
    <Route
      {...rest}
      render={props => (
        deviceId ?
          <Redirect
            to={{
              pathname: toPath,
              state: {from: props.location}
            }}
          />
          : <LoadingScreen/>
      )}
    />
  )
}

export default PublicRoute
