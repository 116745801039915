import React, {useMemo, useState} from 'react'
import {CardElement, CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';
import './stripe-form.scss'
import PropTypes from "prop-types";
import useResponsiveFontSize from "./useResponsiveFontSize";
import {useWindowSize} from "../../../libs/hooks";
import CardForm from "./forms/CardForm";
import SplitForm from "./forms/SplitForm";
import {toast} from "react-toastify";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  return useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          fontSize,
          letterSpacing: "0.025em",
          height: "40px"
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );
};

function StripeForm(props) {
  const stripe = useStripe()
  const elements = useElements()

  const options = useOptions()
  const {reset, onChange} = props

  const isMobile = useWindowSize()[0] <= 768

  const handleChange = (e) => {
    if (e.complete) {
      const element = elements.getElement(CardElement) || elements.getElement(CardNumberElement)

      stripe.createToken(element)
        .then((result) => {
          if (result && result.token) {
            onChange(result.token.id)
          }
        })

      reset(element)
    } else {
      if (e.error) {
        toast.dismiss()
        toast.error(e.error.message)
        toast.clearWaitingQueue()
      }
    }
  }

  return (
    <div className="stripe-form">
      {
        isMobile ?
          <SplitForm
            options={options}
            onChange={handleChange}
          />
          :
          <CardForm
            options={options}
            onChange={handleChange}
          />
      }

    </div>
  )
}

StripeForm.propTypes = {
  reset: PropTypes.func,
  onChange: PropTypes.func.isRequired
}

export default StripeForm


