import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { createStore} from 'redux'
import { Provider } from 'react-redux'
import reducer from './reducers'
import middleware   from './middleware'
import './index.scss'
import App from '../src/components'
import { BrowserRouter as Router } from 'react-router-dom'
import './libs/i18n'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {Amplify} from "aws-amplify";
import config from "./utils/config";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "colteneApiGateway",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
})

const store = createStore(reducer, middleware)

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <Provider store={store} >
        <ToastContainer limit={1}/>
        <Router>
          <App />
        </Router>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)
