import { API } from "aws-amplify"
const apiName = 'colteneApiGateway'

async function trimDoubleSlashes(path) {
  // Avoid double slashes //
  const endpoint = await API.endpoint(apiName)
  if (endpoint && endpoint.substr(-1) === '/' && path.substr(0, 1) === '/') {
    path = path.substr(1)
  }

  return path
}

export async function _baseGetFetch(path, baseParams) {
  return API.get(apiName, await trimDoubleSlashes(path), {'queryStringParameters': baseParams})
}

export async function _basePostFetch(path, baseParams) {
  return API.post(apiName, await trimDoubleSlashes(path), {'body': baseParams})
}
