import React, {useEffect, useState} from 'react'
import languages from '../../../utils/languages'
import './header.scss'
import {useTranslation} from "react-i18next";
import {GlobeIcon} from "../../../icons";
import {ColteneLogoWhite} from "../../../images";
import {useWindowSize} from "../../../libs/hooks";
import axios from "axios";

function Header(props) {
  const {t, i18n} = useTranslation()
  const langCode = languages[i18n.language] ? i18n.language : 'en-CA'
  const [language, setLanguage] = useState('en-CA')

  const isMobile = useWindowSize()[0] <= 768
  useEffect(() => {
    setLanguage(langCode)
    changeAxiosLanguage(langCode)
  }, [props])

  const updatePreferredLanguage = (e) => {
    i18n.changeLanguage(e)
    setLanguage(e)
    changeAxiosLanguage(e)
    window.location.reload()
  }

  const changeAxiosLanguage = (lang) => {
    axios.interceptors.request.use(
      config => {
        config.headers['Accept-Language'] = lang
        return config;
      },
      error => Promise.reject(error)
    );
  }

  return (
    <div className="header-wrapper padding-wrapper d-flex flex-justify-between">
      {
        isMobile && (
          <img src={ColteneLogoWhite} alt="logo" className="logo"/>
        )
      }
      <div className="d-flex">
        {
          languages && Object.keys(languages).length && (
            Object.keys(languages).map((key, index) => (
              <React.Fragment key={index}>
                <div className={`cursor-pointer ${language === key ? 'active-language' : ''}`}
                     onClick={() => updatePreferredLanguage(key)}>
                  {t(`languages.${key}`)}
                </div>
                {
                  Object.keys(languages).length > (index + 1) && (
                    <span className="separator">|</span>
                  )
                }
              </React.Fragment>
            ))
          )
        }
      </div>
      {
        !isMobile && (
          <div className="d-flex">
            <GlobeIcon className="globe-icon"/>
            <a className="link" href="https://global.coltene.com/">{t('coltene')}</a>
          </div>
        )
      }
    </div>
  )
}


export default Header
