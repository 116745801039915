const Roles = {
  home: {
    route: '/devices/:id',
    roles: [
    ]
  },
  pageNotFound: {
    route: '/page-not-found',
    roles: [
    ]
  }
}

const ModuleRoles = {
}

export {
  Roles
}
