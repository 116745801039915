import React from 'react'
import {Route, Switch} from 'react-router-dom'
import PageNotFound from '../../PageNotFound'
import Home from '../../Home'
import RedirectRoute from './RedirectRoute'
import {Roles} from "../../../utils/roles"

function Routes(props) {
  return (
    <Switch>
      <RedirectRoute exact from="/" to={Roles.home.route} />
      <Route path={Roles.home.route}  component={Home} roles={Roles.home.roles}/>
      <Route path="*" component={PageNotFound} roles={Roles.pageNotFound.roles}/>
    </Switch>
  )
}

export default Routes
